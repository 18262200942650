<!--
  导航栏
-->
<style lang="less" scoped>
@import "../../assets/css/variables";

.nav-container {
  //box-sizing: border-box;
  height: 100%;
  padding-bottom: 40px;
  width: ceil(@sidebar-nav-width) + 16; //超出的刚好把scroll-bar给遮盖住
  overflow-x: hidden;
  overflow-y: scroll;

  .fa {
    margin-right: 10px;
    color: #bfcbd9;
  }
  .el-menu-item.is-active {
    .fa {
        color: #20a0ff;
      }
  }
  .router-link-active {
    color: #20a0ff;
  }
  &.collapsed {
    width: 80px;
    background-color: #324157;
    margin: 0;
    padding: 0;
    overflow-x: visible !important;
    overflow-y: visible !important;
    .menu {
      position: relative;
      height: 56px;
      cursor: pointer;
      width: 80px;
      line-height: 56px;
      text-align: center;
      color: #fff;
      &.is-active {
        color: #00b3ee;
        .fa {
          color: #00b3ee;
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      .submenu {
        position: absolute;
        top: 0px;
        left: 80px;
        z-index: 99999;
        width: 140px;
        height: auto;
        list-style: none;
        margin: 0;
        padding: 0;
        background-color: #324157;
        display: none;
        .el-menu-item {
          text-align: left;
          color: #fff;
          &.is-active {
            color: #00b3ee;
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <el-menu
      v-if="!collapsed"
      class="nav-container"
      :default-active="defaultActive"
      theme="dark"
      @open="handleOpen"
      @close="handleClose"
    >
    <!-- dispalyNavMenus是后台传过来的左边导航栏的所有数据 -->
    <!-- isNavMenu 根据权限判断左边导航栏是否显示 -->
      <div v-if="item.isNavMenu" v-for="(item, index) in dispalyNavMenus">


        <!-- 一级导航栏开始 -->
        <el-submenu
          background-color="red"
          v-if="item.hasSubMenu && item.children.length > 0"
          :index="item.path"
        >
        <!--  :index="item.path"是你点击时他去的哪个路由 -->

        <!-- 下面这段是左边导航栏图标与名字设置 -->
          <template slot="title"
            ><i :class="['fa', item.icon]"></i
            >{{ item.meta && item.meta.title }}</template
          >
        <!-- 一级导航栏结束 -->


        <!-- 二级导航栏开始 -->
      <!--二级选项的信息 item1为一个二级选项的所有信息   -->
          <div
            v-if="item1.isTwoNavMenu"
            v-for="(item1, index) in item.children"
          >
            <el-submenu
              v-if="
                item1.hasSubMenu &&
                  item1.hasSubMenu &&
                  item1.children.length > 0
              "
              :index="item.path + '/' + item1.path"
            >
            <!-- 二级导航栏结束 -->

            <!-- 三级导航栏开始 -->
            <!-- item1.meta.title二级导航栏的名字 -->
              <template slot="title">{{
                item1.meta && item1.meta.title
              }}</template>
              <router-link
                v-if="subItem.isNavMenu"
                v-for="subItem in item1.children"
                :to="item.path + '/' + item1.path + '/' + subItem.path"
                exact
              >
                <el-menu-item
                  :index="item.path + '/' + item1.path + '/' + subItem.path"
                >
                  {{ subItem.meta && subItem.meta.title }}
                </el-menu-item>

              </router-link>
              <!-- 三级导航栏结束 -->
            </el-submenu>
          </div>

          <router-link
            v-if="subItem.isNavMenu"
            v-for="subItem in item.children"
            :to="item.path + '/' + subItem.path"
            exact
          >
            <el-menu-item :index="item.path + '/' + subItem.path">
              {{ subItem.meta && subItem.meta.title }}
            </el-menu-item>

          </router-link>
        </el-submenu>
        <router-link v-else :to="item.path" exact>
          <el-menu-item :index="item.path">
            <i
              :class="['fa', item.icon]"
              :style="{
                'font-size': item.fontSize ? item.fontSize + 'px' : '16px'
              }"
            >
            </i
            >{{ item.meta && item.meta.title }}
          </el-menu-item>
        </router-link>
      </div>
    </el-menu>
    <div ref="collapsedMenu" class="nav-container collapsed" v-else>
      <div v-if="item.isNavMenu" v-for="(item, index) in dispalyNavMenus">
        <div
          class="menu"
          :class="$route.path.includes(item.path) ? 'is-active' : ''"
        >
          <template v-if="item.hasSubMenu">
            <div
              @mouseover="showMenu(index, true)"
              @mouseout="showMenu(index, false)"
            >
              <i
                :class="['fa', item.icon]"
                :style="{
                  'font-size': item.fontSize ? item.fontSize + 'px' : '16px'
                }"
              ></i>
            </div>
            <ul
              class="submenu"
              :class="'submenu-hook-' + index"
              @mouseover="showMenu(index, true)"
              @mouseout="showMenu(index, false)"
            >
              <li
                v-for="subItem in item.children"
                v-if="subItem.isNavMenu"
                :key="subItem.path"
                class="el-menu-item"
                style="padding-left: 20px;"
                :class="
                  $route.path.includes(item.path + '/' + subItem.path)
                    ? 'is-active'
                    : ''
                "
                @click="$router.push(item.path + '/' + subItem.path)"
              >
                {{ subItem.meta && subItem.meta.title }}
              </li>
            </ul>
          </template>
          <template v-else>
            <router-link :to="item.path">
              <div
                class="menu el-submenu__title "
                :class="$route.path.includes(item.path) ? 'is-active' : ''"
              >
                <i
                  :class="['fa', item.icon]"
                  :style="{
                    'font-size': item.fontSize ? item.fontSize + 'px' : '16px'
                  }"
                ></i>
              </div>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {getCurrentPerms} from 'src/services/role'

export default {
  mounted() {
    //this.updateFooterStatus(_index);
  },
  data() {
    return {
      collapsed: false,
      defaultActive: "",
      dispalyNavMenus: [],
      pmList: []
    };
  },
  props: {
    defaultCollapsed: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters({ user: "userInfo" })
  },
  mounted() {

  },
  methods: {
    handleOpen() {},
    
    handleClose() {},

    showMenu(index, status) {
      this.$refs.collapsedMenu.getElementsByClassName(
        "submenu-hook-" + index
      )[0].style.display = status ? "block" : "none";
    },

    loginNotice() {
      this.$notify({
        title: "警告",
        message: "您没有登陆权限",
        type: "warning",
        duration: 0
      });
    },
    /**
     * 获取一二级菜单
     */
    fetchMemu(n, compareObj) {
      var insert = false;
      if (n.children) {
        var menu_2 = Object.assign({}, n);
        menu_2.children = [];
        n.children.forEach(function(item) {
          if (compareObj.indexOf(item.name) !== -1) {
            menu_2.children.push(item);
            insert = true;
          }
        });
        if (insert) return menu_2;
      } else {
        if (compareObj.indexOf(n.name) !== -1) return n;
      }
    },

    formatPms(arr) {
      arr.forEach(item => {
        if(item.children) {
          this.formatPms(item.children)
        } else {
          this.pmList.push(item.code)
        }
      })
    },

    async fetchPerms () {
      const resData = await getCurrentPerms();
      if (resData.errorCode === 0) {
        const list = resData.data || []
        this.formatPms(list)
      }
      this.navMenuCheck()
    },

    navMenuCheck() {
      const that = this;
      const routes = this.$router.options.routes[0].children;
      const permissions = (this.user && this.user.permissions) || [];
      const displayMenu = routes || [];
      this.dispalyNavMenus = []
      
      routes.forEach(n => {
        var insert = false;
        if (n.children) {
          var menu_1 = Object.assign({}, n);
          menu_1.children = [];
          n.children.forEach(function(item) {
            var temp = that.fetchMemu(item, permissions);
            if (temp) {
              menu_1.children.push(temp);
              insert = true;
            }
          });
          if (insert) {
            this.dispalyNavMenus.push(menu_1);
          }
        } else {
          if (permissions.indexOf(n.name) !== -1) this.dispalyNavMenus.push(n);
        }
      });
    }
  },
  created() {
    // this.checkActiveIndex();
    // this.navMenuCheck();
    this.fetchPerms();

    this.collapsed = this.defaultCollapsed;
    this.defaultActive = this.$route.path;
  },
  watch: {
    'user.permissions': {
      handler (newVal) {
        this.fetchPerms();
      },
      immediate: true,
    },
    defaultCollapsed() {
      this.collapsed = this.defaultCollapsed;
    }
  }
};
</script>
