<!--
  系统内的header  带有userinfo和菜单
-->
<style lang="less" scoped>
  @import "../../assets/css/variables";

  .header {
    height: @header-height;
    background-color: #fff;
    color: @baseColor;
    line-height: @header-height;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    position: fixed;
    font-size: 14px;
    .logo-container {
      position: absolute;
      width: @sidebar-nav-width;
      height: 100%;
      top: 0;
      text-align: center;
      background-color: #324157;
      .header-logo {
      }
      .header-no-logo {
        color: #fff;
        position: absolute;
        width: 80px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
      }
    }
    & .collapsed-logo {
      width: 80px !important;
    }
    .header-container {
      position: absolute;
      height: 100%;
      top: 0;
      left: @sidebar-nav-width;
      right: 0;
    }
    & .collapsed {
      left: 80px;
    }
    .system-title {
      margin: 0;
      padding: 0;
      color: #666;
    }
  }

  .user-info {
    position: relative;
    height: 60px;
    line-height: 60px;
    padding-right: 30px;
    .user-image {
      position: relative;
      height: 40px;
      width: 40px;
      background-size: cover;
      border-radius: 24px;
      overflow: hidden;
      vertical-align: middle;
      display: inline-block;
      background-position: center;
    }
    .user-name {
      color: @baseColor;
    }
    .badge {
      line-height: 30px;
      .fa {
        margin-top: 4px;
        font-size: 20px;
      }
    }
  }

  .icon-dark {
    font-size: 16px !important;
  }

  .layout-collapse {
    width: 70px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.01);
    text-align: center;
    font-size: 20px;
    color: #333;
  }

  .el-dropdown-menu {
    font-size: 14px;
    color: #666;
  }

  .alarm-dropdown {
    cursor: default;
    &:hover {
      background-color: transparent;
      color: #666;
    }
  }
</style>

<template keep-live>
  <header class="header active-bg">
    <div class="logo-container" :class="{ 'collapsed-logo': collapsed }">
      <logo
        v-if="!collapsed"
        class="header-logo"
        size="large"
        alt="威霖集团"
      ></logo>
      <div v-else class="header-no-logo">威霖</div>
    </div>
    <div class="header-container" :class="{ collapsed: collapsed }">
      <div @click="handleCollapsed" class="layout-collapse pull-left">
        <i class="fa fa-bars"></i>
      </div>
      <div class="pull-right user-info">
        <el-dropdown @command="handleUserMenu">
          <span class="el-dropdown-link user-name">
            <div class="user-image" v-lazy:background-image="userImage"></div>
            &nbsp;{{ user.name }}&nbsp;
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu class="el-dropdown-menu-sm" slot="dropdown">
            <el-dropdown-item command="editpwd">
              <i class="fa fa-check-square-o"></i>
              &nbsp;修改密码
            </el-dropdown-item>
            <el-dropdown-item command="userinfo">
              <i class="fa fa-exclamation-circle"></i>
              &nbsp;个人信息
            </el-dropdown-item>
            <el-dropdown-item command="logout" divided>
              <i class="fa fa-sign-out"></i>
              &nbsp;安全退出
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>
<script>
  import Logo from "./logo.vue";
  import defaultImg from "../../assets/images/defaultImg.jpg";
  import { mapGetters, mapActions } from "vuex";
  import { Notification } from "element-ui";
  import { API_ROOT } from "../../config";
  import { logout } from "../../services/ucenter";
  // import {getDeviceStatiStatus} from "../../services/device"
  // import {searchAlarmsByKey} from "../../services/alarm";
  import moment from "moment";
  export default {
    data() {
      return {
        moment,
        Menu_Alarm: true,
        selectedIndex: -1, //一级菜单选中的下标
        isAdmin: false,
        collapsed: false,
        alarmTotal: 0,
        alarmList: [],
        onlineCount: 0,
        offlineCount: 0,
        defaultImg,
      };
    },
    props: {
      defaultCollapsed: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
    },
    components: {
      Logo,
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
      userImage() {
        return this.user.image ? API_ROOT + this.user.image : defaultImg;
      },
    },
    methods: {
      /**
       * 判断铃铛显隐
       */

      ...mapActions([
        //'addToCart'
      ]),
      /**
       *
       */
      async handleUserMenu(command) {
        switch (command) {
          case "editpwd":
            window.router.push({
              path: "/ucenter/editpwd",
            });
            break;
          case "userinfo":
            window.router.push({
              path: "/ucenter/userinfo",
            });
            break;
          case "logout":
            this.$confirm("确认要退出系统吗", "提示", {
              type: "warning",
            }).then(() => {
              this.doLogout();
            });
            //}
            break;
        }
      },
      async doLogout() {
        const logoutData = await logout();
        //不论结果直接跑去登录页面
        //if (logoutData.errorCode === 1 && logoutData.data.result) {
        this.$store.dispatch("refreshUserInfo", null);
        window.router.push({ path: "/login" });
      },

      /**
       * 刷新
       */
      // async refreshCurrentAlarmList(){
      //   const responseData = await searchAlarmsByKey({key: '', isFinish: false}, {size: 3, page: 1});
      //   if (responseData.errorCode == 0 && responseData.data) {
      //     this.alarmList = responseData.data.content || [];
      //     this.alarmList.forEach((alarmObj) => {
      //       let remainTime = new Date().getTime() - alarmObj.startTime;
      //       let remainMinutes = Math.ceil(remainTime / 60000);
      //       if (remainMinutes < 60) {
      //         alarmObj.timeAgo = remainMinutes + '分钟';
      //       } else if (remainMinutes >= 60 && remainMinutes < 60 * 24) {
      //         alarmObj.timeAgo = Math.ceil(remainMinutes / 60) + '小时';
      //       } else {
      //         alarmObj.timeAgo = Math.ceil(remainMinutes / 60 / 24) + '天';
      //       }
      //     })
      //     this.alarmTotal = responseData.data.totalElements;
      //   }
      //   // this.refreshDeviceStatus();
      // },

      /**
       * 刷新在线|离线设备的状态
       * @returns {Promise.<void>}
       */
      // async refreshDeviceStatus(){
      //   const responseData = await getDeviceStatiStatus();
      //   if (responseData.errorCode == 0 && responseData.data) {
      //     this.offlineCount = responseData.data.offlineCount;
      //     this.onlineCount = responseData.data.onlineCount + responseData.data.alarmCount;
      //   }
      // },

      handleCollapsed() {
        this.collapsed = !this.collapsed;
        this.$emit("collapsed", this.collapsed);
      },
    },
    async created() {
      this.collapsed = this.defaultCollapsed;
      // window.app.$on('alarm-update', (alarmObj) => {
      //   this.refreshCurrentAlarmList(alarmObj);
      // });
      // this.checkPermission();
    },
    watch: {
      user(newVal) {
        if (newVal) {
          //alert(1)
          //console.log(newVal)
        }
      },
      $route: function() {
        //this.checkActiveIndex();
      },
    },
  };
</script>
