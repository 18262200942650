<style lang="less">
  @import './assets/css/index.less' ;

</style>
<style lang="less" scoped>
  @import './assets/css/variables.less';

  .app-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .main {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    width: 100%;
  }

  //有header的时候，main向下移动64px
  .my-header + .main {
    top: @header-height;
  }

  .isVisible {
    visibility: hidden;
  }

  .layout-nav {
    position: relative;
    z-index: 9999;
    min-height: 100%;
    width: ceil(@sidebar-nav-width); //超出的刚好把scroll-bar给遮盖住
    overflow-x: hidden;
    &.collapsed {
      width: 80px !important;
      overflow-x: visible;
    }
  }

  .layout-panel {
    //flex: 1;  //为了后面可能出现的要求兼容ie10 不用flex-basic的方式布局
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #f4f4f4;
    overflow-x: hidden;
    &.collapsed {
      left: 80px !important;
    }
  }

  .layout-nav + .layout-panel {
    left: @sidebar-nav-width;
  }

  /*.bottom {
    margin-top: -264px;
    width: 100%;
    height: 264px;
  }*/
</style>

<template>
  <div class="app-wrapper">
    <my-header v-on:collapsed="collapsed=arguments[0]" :defaultCollapsed="collapsed" class="my-header"
               v-if="frameLayerFlag"></my-header>
    <div class="main">
      <my-nav :defaultCollapsed="collapsed" :active-index="menuIndex" v-if="frameLayerFlag"
              class="layout-nav nav-menu" :class="{'collapsed':collapsed}"></my-nav>
      <div class="layout-panel" :class="{'collapsed':collapsed}">
        <router-view></router-view>
      </div>
    </div>
  </div>

</template>

<script>
  import Nav from './views/common/nav.vue';
  import Header from './views/common/header.vue';
  import Footer from './views/common/footer-justcr.vue';
  import cookie from './utils/http/cookie';
  import {getAllRole} from './services/role';
  import {mapGetters, mapActions} from "vuex";
  import {Notification, Message} from 'element-ui';
  import {getTempUserInfo, getPmgsInfo} from './services/user';

  export default {
    components: {
      'my-nav': Nav,
      'my-header': Header,
      'my-footer': Footer
    },
    data () {
      return {
        collapsed: false,//没有折叠  ture 折叠
        loadPermission: true, //加载权限
        frameLayerFlag: true,   //是否按照当前的固定结构布局  true 是 false 自己独占一页
        menuIndex: '0', //当前展开的菜单索引
      }
    },
    computed: {
      // 结构wuex存储的用户信息
      ...mapGetters({
        user: 'userInfo'
      })
    },
    methods: {
      //登录页面不显示头部
      checkShowHeader(){
        const currentRoute = window.router.currentRoute;
        if (currentRoute.fullPath.indexOf('/login') === -1 &&
          currentRoute.fullPath.indexOf('/forgetpwd') === -1) {
          this.frameLayerFlag = true;
        } else {
          this.frameLayerFlag = false;
        }
      },

      // 填充用户信息保存到全局变量
      async setUserToStore () {
        // 填充用户
        const userData = await getTempUserInfo();
        if (userData.errorCode == 0 && userData.data) {
          const userInfo = userData.data || {};
          cookie("userInfo", JSON.stringify(userInfo));
           this.$store.dispatch('refreshUserInfo', userInfo);
           if(userData.data.role === 'bigScrean') {
             window.router.replace({path: '/dvcStat'});
           }
        } else {
          // window.router.push({path: '/login'});
        }

        // 填充角色1
        const roleData = await getAllRole();
        if (roleData && roleData.data) {
          this.$store.dispatch('refreshRoles', roleData.data.list);
        }
      }
    },
    mounted() {
      this.checkShowHeader();
      this.setUserToStore();
    },
    watch: {
      '$route': function () {
        this.checkShowHeader();
      }
    },
  }

</script>
