import { render, staticRenderFns } from "./header.vue?vue&type=template&id=5584fae4&scoped=true&keep-live=true&"
import script from "./header.vue?vue&type=script&lang=js&"
export * from "./header.vue?vue&type=script&lang=js&"
import style0 from "./header.vue?vue&type=style&index=0&id=5584fae4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5584fae4",
  null
  
)

export default component.exports